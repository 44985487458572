<template>
  <tr class="cursor-pointer items-center bg-white hover:bg-gray-50">
    <td class="px-1">
      <pdfsvg
        :hasFiles="order.customerDocs && order.customerDocs.length"
        @click="
          order.customerDocs && order.customerDocs.length
            ? (showFilesModal = true)
            : null
        "
      ></pdfsvg>
    </td>
    <td @click="$router.push(`/orders/${order._id}`)" class="px-1">
      {{ order.inquiryIdString }}
    </td>
    <td @click="$router.push(`/orders/${order._id}`)" class="px-1">
      {{ order.description }}
    </td>
    <td @click="$router.push(`/orders/${order._id}`)" class="px-1">
      {{ $moment(order.date).format("DD MMM YYYY") }}
    </td>
    <td
      @click="$router.push(`/orders/${order._id}`)"
      class="px-1 flex items-center justify-center py-1.5"
    >
      <button :class="orderStatusStyle" class="px-4 py-1 rounded">
        {{ orderInquiryStatus }}
      </button>
    </td>
    <td @click="$router.push(`/orders/${order._id}`)" class="px-1">
      {{
        order.latestTrackingData && order.latestTrackingData.location
          ? order.latestTrackingData.location
          : "N/A"
      }}
    </td>
    <td @click="$router.push(`/orders/${order._id}`)" class="px-1">
      {{
        order.dateOrdered
          ? $moment(order.dateOrdered).format("DD MMM YYYY")
          : "N/A"
      }}
    </td>
    <td @click="$router.push(`/orders/${order._id}`)" class="px-1">
      {{
        order.eta && $moment(order.eta).diff($moment(), "days") > 0
          ? $moment(order.eta).diff($moment(), "days")
          : "N/A"
      }}
    </td>
    <td @click="$router.push(`/orders/${order._id}`)" class="px-1">
      {{ order.eta ? $moment(order.eta).format("DD MMM YYYY") : "N/A" }}
    </td>
    <td @click="$router.push(`/orders/${order._id}`)" class="px-1">
      {{
        order.grandTotal ? `$${parseFloat(order.grandTotal).toFixed(2)}` : "N/A"
      }}
    </td>
    <td @click="$router.push(`/orders/${order._id}`)" class="px-1">
      {{ paidAmount }}
    </td>
    <td
      @click="$router.push(`/orders/${order._id}`)"
      :class="{ 'font-bold text-green-500': outstandingAmount === 'PAID' }"
      class="px-1"
    >
      {{ outstandingAmount }}
    </td>
    <td @click="$router.push(`/orders/${order._id}`)" class="px-1">
      <button :class="paymentDueDateStyle">
        {{
          order.paymentDueDate
            ? $moment(order.paymentDueDate).format("DD MMM YYYY")
            : "N/A"
        }}
      </button>
    </td>
  </tr>
  <FilesModal
    :customerDocs="order.customerDocs"
    v-show="showFilesModal"
    @close-modal="showFilesModal = false"
  />
</template>

<script>
import pdfsvg from "@/components/base/pdfsvg";
import FilesModal from "../modals/FilesModal";
export default {
  name: "OrderTableItem",
  props: ["order"],
  data() {
    return {
      showFilesModal: false,
    };
  },
  computed: {
    paidAmount() {
      if (!this.order || !this.order.payments || !this.order.payments.length)
        return "N/A";
      let paidAmount = 0;

      this.order.payments.forEach((payment) => {
        paidAmount += payment.amount;
      });

      return `$${paidAmount.toFixed(2)}`;
    },
    outstandingAmount() {
      if (!this.order || this.order.dueAmount === null) return "N/A";
      let outstandingAmount = parseFloat(
        parseFloat(this.order.dueAmount).toFixed(2)
      );

      if (outstandingAmount === "N/A") return outstandingAmount;

      if (
        outstandingAmount === 0 ||
        outstandingAmount === 0.0 ||
        outstandingAmount <= 0.0
      ) {
        outstandingAmount = "PAID";
        return outstandingAmount;
      }

      if (outstandingAmount !== "N/A")
        outstandingAmount = `$${outstandingAmount}`;

      return outstandingAmount;
    },
    orderInquiryStatus() {
      switch (this.order.status) {
        case "pricing":
          return "Pricing";
        case "onHold":
          return "On Hold";
        case "rejected":
          return "Rejected";
        case "approved":
          return "Approved";
        case "completed":
          return "Completed";
        default:
          return "None";
      }
    },
    orderStatusStyle() {
      switch (this.order.status) {
        case "pricing":
          return {
            "bg-yellow-50 text-yellow-600": true,
          };
        case "completed":
          return {
            "bg-green-50 text-green-400": true,
          };
        case "onHold":
          return {
            "bg-blue-50 text-blue-400": true,
          };
        case "approved":
          return {
            "bg-blue-50 text-green-400": true,
          };
        case "rejected":
          return {
            "bg-red-50 text-red-400": true,
          };
        default:
          return {};
      }
    },
    paymentDueDateStyle() {
      if (!this.order || !this.order.paymentDueDate) return {};

      if (
        this.$moment(this.order.paymentDueDate).diff(this.$moment(), "days") < 0
      ) {
        return { "bg-red-100 px-3 py-2 rounded text-red-500": true };
      } else {
        return { "py-2 px-3 rounded": true };
      }
    },
  },
  components: {
    pdfsvg,
    FilesModal,
  },
};
</script>

<style>
</style>