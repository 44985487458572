<template>
  <div class="w-full">
    <div
      class="
        bg-gradient-to-r
        from-gray-300
        bg-gray-100
        p-1
        flex
        justify-between
        z-20
      "
    >
      <ul class="flex items-center ml-1">
        <div class="relative text-gray-600">
          <h1 class="px-2 text-black font-semibold">Orders</h1>
        </div>
      </ul>
      <ul class="flex items-center relative">
        <!-- <li
          style="color: #cfb47e"
          class="
            cursor-pointer
            bg-white
            border
            text-sm
            py-1
            rounded-lg
            px-3
            hover:bg-black
            mr-2
            flex
            items-center
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-3 w-3 fill-current mr-1"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#cfb47e"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <line x1="4" y1="21" x2="4" y2="14"></line>
            <line x1="4" y1="10" x2="4" y2="3"></line>
            <line x1="12" y1="21" x2="12" y2="12"></line>
            <line x1="12" y1="8" x2="12" y2="3"></line>
            <line x1="20" y1="21" x2="20" y2="16"></line>
            <line x1="20" y1="12" x2="20" y2="3"></line>
            <line x1="1" y1="14" x2="7" y2="14"></line>
            <line x1="9" y1="8" x2="15" y2="8"></line>
            <line x1="17" y1="16" x2="23" y2="16"></line>
          </svg>
          Filter
        </li> -->

        <input
          v-model="orderSearchString"
          type="search"
          name="search"
          placeholder="Search"
          class="
            bg-white
            border-white border
            h-7
            w-96
            px-4
            mr-4
            pr-10
            rounded-lg
            text-sm
            focus:outline-none
          "
          @keydown="handleSearchKeyPress"
          @keyup="handleSearchKeyUp"
        />
        <button type="submit" class="absolute right-0 top-0 mt-1.5 mr-6">
          <svg
            class="h-4 w-4 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            version="1.1"
            id="Capa_1"
            x="0px"
            y="0px"
            viewBox="0 0 56.966 56.966"
            style="enable-background: new 0 0 56.966 56.966; color: #cfb47e"
            xml:space="preserve"
            width="512px"
            height="512px"
          >
            <path
              d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"
            />
          </svg>
        </button>
      </ul>
    </div>
    <div class="border-b border-gray-200">
      <!-- GRAPHS -->
      <div class="w-full grid grid-cols-1 gap-0 md:grid-cols-3">
        <div class="bg-gray-100 w-full chart">
          <BarChart
            class="chart"
            :options="orderStatusChartOptions"
            :chartData="orderStatusChartData"
          ></BarChart>
        </div>
        <div class="bg-gray-100 w-full">
          <LineChart
            class="chart"
            :options="orderStatsChartOptions"
            :chartData="placedOrderStatsData"
          ></LineChart>
        </div>
        <div class="bg-gray-100 w-full">
          <BarChart
            class="chart"
            :options="dueAmountChartOptions"
            :chartData="paymentChartData"
          ></BarChart>
        </div>
      </div>
      <!-- GRAPHS -->
      <ul
        class="
          flex
          filter-buttons
          gap-x-6
          border border-t-0 border-l-0 border-r-0 border-gray-200
        "
      >
        <li
          @click="$store.commit(mutations.CLEAR_FILTERS), getOrders()"
          :class="{
            'border-gray-700 font-bold':
              filteredOrderStatus === 'none' &&
              !$store.getters.orderOutsandingFilter,
          }"
          class="
            cursor-pointer
            outline-none
            border-transparent border-4 border-t-0 border-r-0 border-l-0
            hover:border-current hover:border-gray-700
            text-sm
            px-2
            py-2
          "
        >
          All orders
        </li>
        <li
          :class="{
            'border-gray-700 font-bold': filteredOrderStatus === 'pricing',
          }"
          @click="
            $store.commit(mutations.CLEAR_STATUS_FILTERS),
              $store.commit(mutations.ADD_FILTERS, [
                { type: 'inquiryStatus', value: 'pending' },
                { type: 'inquiryStatus', value: 'pricing' },
                { type: 'inquiryStatus', value: 'notAvailable' },
              ]),
              $store.commit(mutations.SET_PAGE, 1),
              getOrders()
          "
          class="
            cursor-pointer
            outline-none
            hover:border-gray-700
            border-transparent border-4 border-t-0 border-r-0 border-l-0
            hover:border-current
            text-sm
            px-2
            py-2
          "
        >
          Pricing
        </li>
        <li
          class="
            cursor-pointer
            outline-none
            hover:border-gray-700
            border-transparent border-4 border-t-0 border-r-0 border-l-0
            hover:border-current
            text-sm
            px-2
            py-2
          "
          :class="{
            'border-gray-700 font-bold': filteredOrderStatus === 'onHold',
          }"
          @click="
            $store.commit(mutations.CLEAR_STATUS_FILTERS),
              $store.commit(mutations.ADD_FILTERS, [
                { type: 'inquiryStatus', value: 'sent' },
                { type: 'customerStatus', value: 'onHold' },
              ]),
              $store.commit(mutations.SET_PAGE, 1),
              getOrders()
          "
        >
          On Hold
        </li>
        <li
          class="
            cursor-pointer
            outline-none
            hover:border-gray-700
            border-transparent border-4 border-t-0 border-r-0 border-l-0
            hover:border-current
            text-sm
            px-2
            py-2
          "
          :class="{
            'border-gray-700 font-bold': filteredOrderStatus === 'approved',
          }"
          @click="
            $store.commit(mutations.CLEAR_STATUS_FILTERS),
              $store.commit(mutations.ADD_FILTERS, [
                { type: 'inquiryStatus', value: 'notAvailable' },
                { type: 'inquiryStatus', value: 'pricing' },
                { type: 'inquiryStatus', value: 'sent' },
                { type: 'inquiryStatus', value: 'shipped' },
                { type: 'inquiryStatus', value: 'onHand' },
                { type: 'inquiryStatus', value: 'delivered' },
                { type: 'inquiryStatus', value: 'pending' },
                { type: 'customerStatus', value: 'approved' },
              ]),
              $store.commit(mutations.SET_PAGE, 1),
              getOrders()
          "
        >
          Approved
        </li>
        <li
          class="
            cursor-pointer
            outline-none
            hover:border-gray-700
            border-transparent border-4 border-t-0 border-r-0 border-l-0
            hover:border-current
            text-sm
            px-2
            py-2
          "
          :class="{
            'border-gray-700 font-bold': filteredOrderStatus === 'completed',
          }"
          @click="
            $store.commit(mutations.CLEAR_STATUS_FILTERS),
              $store.commit(mutations.ADD_FILTERS, [
                { type: 'inquiryStatus', value: 'closed' },
                { type: 'customerStatus', value: 'approved' },
              ]),
              $store.commit(mutations.SET_PAGE, 1),
              getOrders()
          "
        >
          Completed
        </li>
        <li
          class="
            cursor-pointer
            outline-none
            hover:border-gray-700
            border-transparent border-4 border-t-0 border-r-0 border-l-0
            hover:border-current
            text-sm
            px-2
            py-2
          "
          :class="{
            'border-gray-700 font-bold': filteredOrderStatus === 'rejected',
          }"
          @click="
            $store.commit(mutations.CLEAR_STATUS_FILTERS),
              $store.commit(mutations.ADD_FILTERS, [
                { type: 'inquiryStatus', value: 'closed' },
                { type: 'customerStatus', value: 'rejected' },
              ]),
              $store.commit(mutations.SET_PAGE, 1),
              getOrders()
          "
        >
          Rejected
        </li>

        <li
          class="
            cursor-pointer
            outline-none
            hover:border-gray-700
            border-transparent border-4 border-t-0 border-r-0 border-l-0
            hover:border-current
            text-sm
            px-2
            ml-20
            py-2
          "
          :class="{
            'border-gray-700 font-bold': $store.getters.orderOutsandingFilter,
          }"
          @click="
            $store.commit(mutations.CLEAR_STATUS_FILTERS),
              $store.commit(mutations.SET_OUTSTANDING_FILTER),
              $store.commit(mutations.SET_PAGE, 1),
              getOrders()
          "
        >
          Outstanding
        </li>
      </ul>
    </div>
  </div>

  <div class="md:overflow-auto slim-scrollbar">
    <div class="">
      <div class="flex">
        <!-- below px-6 py-4 -->
        <div class="container">
          <div></div>
          <!-- <div
            class="grid place-items-center h-96 text-gray-500 dark:text-gray-300 text-xl border-4 border-gray-300 border-dashed"
          >
            Content
          </div> -->
        </div>
      </div>

      <!-- TABLE START -->
      <div class="flex">
        <div class="container">
          <div class="inq-table">
            <div>
              <div class="flex flex-col">
                <div class="flex-grow relative">
                  <table class="relative w-full">
                    <thead
                      class="w-full border border-t-0 border-r-0 border-l-0"
                    >
                      <tr class="bg-white text-left">
                        <th
                          class="
                            top-0
                            px-1
                            py-2
                            text-gray-600
                            bg-white
                            text-xs
                            hover:bg-gray-100
                            pl-2
                          "
                        ></th>
                        <th
                          class="
                            top-0
                            px-1
                            py-2
                            text-gray-600
                            bg-white
                            text-xs
                            hover:bg-gray-100
                            cursor-pointer
                          "
                          :class="{
                            'bg-gray-100 pl-2':
                              $store.getters.orderSort.match(/inquiryId/gi),
                          }"
                          @click="
                            $store.getters.orderSort === '' ||
                            $store.getters.orderSort === '-inquiryId'
                              ? $store.commit(
                                  mutations.SET_ORDER_SORT,
                                  'inquiryId'
                                )
                              : $store.commit(
                                  mutations.SET_ORDER_SORT,
                                  '-inquiryId'
                                ),
                              getOrders()
                          "
                        >
                          <div class="flex gap-2 items-center">
                            ORDER #
                            <img
                              v-if="
                                $store.getters.orderSort.match(/inquiryId/gi)
                              "
                              :class="{
                                'rotate-sort-icon':
                                  $store.getters.orderSort === 'inquiryId',
                              }"
                              class="w-3 h-3 transition-all"
                              src="../../assets/dropdownicon.svg"
                              alt=""
                            />
                          </div>
                        </th>
                        <th
                          class="
                            top-0
                            px-1
                            py-2
                            text-gray-600
                            bg-white
                            text-xs
                            hover:bg-gray-100
                          "
                        >
                          DESCRIPTION
                        </th>
                        <th
                          class="
                            top-0
                            px-1
                            py-2
                            text-gray-600
                            bg-white
                            text-xs
                            hover:bg-gray-100
                            cursor-pointer
                          "
                          :class="{
                            'bg-gray-100 px-2':
                              $store.getters.orderSort.match(/date/gi),
                          }"
                          @click="
                            $store.getters.orderSort === '' ||
                            $store.getters.orderSort === '-date'
                              ? $store.commit(mutations.SET_ORDER_SORT, 'date')
                              : $store.commit(
                                  mutations.SET_ORDER_SORT,
                                  '-date'
                                ),
                              getOrders()
                          "
                        >
                          <div class="flex gap-2 items-center">
                            DATE LOGGED
                            <img
                              v-if="$store.getters.orderSort.match(/date/gi)"
                              :class="{
                                'rotate-sort-icon':
                                  $store.getters.orderSort === 'date',
                              }"
                              class="w-3 h-3 transition-all"
                              src="../../assets/dropdownicon.svg"
                              alt=""
                            />
                          </div>
                        </th>
                        <th
                          class="
                            top-0
                            px-1
                            py-2
                            text-gray-600
                            bg-white
                            text-xs
                            hover:bg-gray-100
                          "
                        >
                          ORDER STATUS
                        </th>
                        <th
                          class="
                            top-0
                            px-1
                            py-2
                            text-gray-600
                            bg-white
                            text-xs
                            hover:bg-gray-100
                          "
                        >
                          LAST LOCATION
                        </th>
                        <th
                          class="
                            top-0
                            px-1
                            py-2
                            text-gray-600
                            bg-white
                            text-xs
                            hover:bg-gray-100
                          "
                        >
                          DATE ORDERED
                        </th>
                        <th
                          class="
                            top-0
                            px-1
                            py-2
                            text-gray-600
                            bg-white
                            text-xs
                            hover:bg-gray-100
                          "
                        >
                          LEAD TIME (D)
                        </th>
                        <th
                          class="
                            top-0
                            px-1
                            py-2
                            text-gray-600
                            bg-white
                            text-xs
                            hover:bg-gray-100
                          "
                        >
                          ETA
                        </th>
                        <th
                          class="
                            top-0
                            px-1
                            py-2
                            text-gray-600
                            bg-white
                            text-xs
                            hover:bg-gray-100
                          "
                        >
                          ORDER TOTAL
                        </th>
                        <th
                          class="
                            top-0
                            px-1
                            py-2
                            text-gray-600
                            bg-white
                            text-xs
                            hover:bg-gray-100
                          "
                        >
                          PAID TOTAL
                        </th>
                        <th
                          class="
                            top-0
                            px-1
                            py-2
                            text-gray-600
                            bg-white
                            text-xs
                            hover:bg-gray-100
                          "
                        >
                          OUTSTANDING
                        </th>
                        <th
                          class="
                            top-0
                            px-1
                            py-2
                            text-gray-600
                            bg-white
                            text-xs
                            hover:bg-gray-100
                          "
                        >
                          DUE ON
                        </th>
                      </tr>
                    </thead>
                    <tbody class="text-xs">
                      <!-- ADD INQUIRY ITEM -->

                      <OrderTableItem
                        v-for="order in orders"
                        :key="order._id"
                        :order="order"
                      ></OrderTableItem>
                    </tbody>
                  </table>
                  <div class="mt-6"></div>
                  <div
                    v-if="paginationLinks.length > 1"
                    class="flex justify-end"
                  >
                    <ul class="flex text-xs mb-6 px-6">
                      <li
                        class="
                          mx-1
                          hover:bg-gray-700 hover:text-gray-200
                          bg-gray-200
                          text-gray-500
                          rounded-lg
                        "
                      >
                        <button
                          v-if="currentOrderPage - 1 > 0"
                          @click="
                            $store.commit(
                              mutations.SET_PAGE,
                              currentOrderPage - 1
                            ),
                              getOrders()
                          "
                          class="px-3 py-2 flex items-center font-bold"
                          href="#"
                        >
                          <span class="mx-1">previous</span>
                        </button>
                      </li>
                      <li
                        v-for="(link, i) in paginationLinks"
                        :key="i"
                        :class="{
                          'bg-gray-700 text-gray-200':
                            link === currentOrderPage,
                          'bg-gray-200 text-gray-700':
                            link !== currentOrderPage,
                        }"
                        class="
                          mx-1
                          hover:bg-gray-700 hover:text-gray-200
                          rounded-lg
                        "
                      >
                        <button
                          @click="
                            $store.commit(mutations.SET_PAGE, link), getOrders()
                          "
                          :disabled="link === '...'"
                          class="px-3 py-2 font-bold"
                        >
                          {{ link }}
                        </button>
                      </li>
                      <li class="mx-1 bg-gray-200 text-gray-700 rounded-lg">
                        <button
                          v-if="currentOrderPage + 1 <= totalOrderPages"
                          @click="
                            $store.commit(
                              mutations.SET_PAGE,
                              currentOrderPage + 1
                            ),
                              getOrders()
                          "
                          class="
                            px-3
                            py-2
                            hover:bg-gray-700 hover:text-gray-200
                            flex
                            items-center
                            font-bold
                          "
                        >
                          <span class="mx-1">Next</span>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- TABLE END-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderTableItem from "@/components/modules/Dashboard/OrderTableItem";
import { mapActions, mapGetters } from "vuex";
import mutations from "@/utils/mutation-types";

import { BarChart, LineChart } from "vue-chart-3";

export default {
  name: "Dashboard",
  data() {
    return {
      window: window,
      searchTypingTimeout: 500,
      timer: null,
      mutations,
      orderStatusChartOptions: {
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: "Order Status",
          },
        },
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },

      dueAmountChartOptions: {
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: "Credit & Payments",
          },
        },
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
      orderStatsChartData: {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
        datasets: [
          {
            label: "Placed Orders",
            data: [34, 65, 89, 87, 54, 43, 67],
            fill: false,
            borderColor: "rgb(75, 192, 192)",
            tension: 0.1,
          },
          {
            label: "Total Orders",
            fill: false,
            data: [65, 59, 80, 81, 56, 55, 40],
            borderColor: "#F97D75",
            tension: 0.1,
          },
        ],
      },
      orderStatsChartOptions: {
        plugins: {
          legend: {
            display: true,
            labels: {
              boxHeight: 1,
            },
          },
          title: {
            display: false,
            text: "Placed Orders",
          },
        },
        scales: {
          y: {
            offset: true,
          },
        },
      },
    };
  },
  methods: {
    ...mapActions([
      "getOrders",
      "getOrderStatusStats",
      "getPaymentStats",
      "getPlacedOrderStats",
    ]),
    handleSearchKeyPress() {
      window.clearTimeout(window.searchTimer);
      this.$store.commit(mutations.SET_LOADING, true);
    },
    handleSearchKeyUp() {
      window.clearTimeout(window.searchTimer);

      window.searchTimer = window.setTimeout(() => {
        this.$store.dispatch("getOrders");
        // console.log("test");
      }, this.searchTypingTimeout);
      // console.log(this.timer);
    },
  },
  mounted() {
    this.getOrders();
    this.getOrderStatusStats();
    this.getPlacedOrderStats();
    this.getPaymentStats();
  },
  computed: {
    ...mapGetters([
      "orders",
      "orderFilters",
      "totalOrderPages",
      "currentOrderPage",
      "orderStatusStatsData",
      "placedOrderStatsData",
      "paymentChartData",
    ]),
    orderSearchString: {
      get() {
        return this.$store.getters.orderSearchString;
      },
      set(val) {
        this.$store.commit(mutations.SET_ORDER_SEARCH_STRING, val);
      },
    },
    orderStatusChartData() {
      return {
        labels: ["Pricing", "On Hold", "Approved", "Completed", "Rejected"],
        datasets: [
          {
            data: this.orderStatusStatsData || [0, 0, 0, 0, 0],
            backgroundColor: [
              "#F5AC29",
              "#64BBFD",
              "#b9cfea",
              "#39D4A1",
              "#F97D75",
            ],
            borderColor: [
              "#FFFBEB",
              "#EFF6FF",
              "#39D4A1",
              "#ECFDF5",
              "#FEF2F2",
            ],
            borderWidth: 1,
          },
        ],
      };
    },
    filteredOrderStatus() {
      if (!this.orderFilters || !this.orderFilters.length) {
        return "none";
      }

      // Based on inquiryStatus field on inquiry collection
      const pricing = this.orderFilters.findIndex((filter) => {
        return filter.type === "inquiryStatus" && filter.value === "pricing";
      });
      const pending = this.orderFilters.findIndex((filter) => {
        return filter.type === "inquiryStatus" && filter.value === "pending";
      });
      const notAvailable = this.orderFilters.findIndex((filter) => {
        return (
          filter.type === "inquiryStatus" && filter.value === "notAvailable"
        );
      });
      const sent = this.orderFilters.findIndex((filter) => {
        return filter.type === "inquiryStatus" && filter.value === "sent";
      });
      const closed = this.orderFilters.findIndex((filter) => {
        return filter.type === "inquiryStatus" && filter.value === "closed";
      });

      // Based on customerStatus field on inquiry collection
      const onHold = this.orderFilters.findIndex((filter) => {
        return filter.type === "customerStatus" && filter.value === "onHold";
      });
      const approved = this.orderFilters.findIndex((filter) => {
        return filter.type === "customerStatus" && filter.value === "approved";
      });
      const rejected = this.orderFilters.findIndex((filter) => {
        return filter.type === "customerStatus" && filter.value === "rejected";
      });

      if (approved !== -1 && closed === -1) {
        return "approved";
      }
      if (pricing !== -1 && pending !== -1 && notAvailable !== -1) {
        return "pricing";
      }
      if (sent !== -1 && onHold !== -1) {
        return "onHold";
      }
      if (approved !== -1 && closed !== -1) {
        return "completed";
      }
      if (rejected !== -1 && closed !== -1) {
        return "rejected";
      }
      return "none";
    },
    paginationLinks() {
      const { page, totalPages } = this.$store.state.orders;

      const pageNumArr = [];

      const arr = [];

      if (totalPages > 6) {
        for (let i = 1; i <= totalPages; i++) pageNumArr.push(i);
        const currentPageIndex = pageNumArr.indexOf(page);
        let sliceStartIndex = 0;
        if (currentPageIndex > 0) sliceStartIndex = currentPageIndex - 1;

        if (pageNumArr.slice(sliceStartIndex).length > 6) {
          arr.push(...pageNumArr.slice(sliceStartIndex, sliceStartIndex + 3));
          arr.push("...");
          arr.push(...pageNumArr.slice(-3));
        } else {
          arr.push(...pageNumArr.slice(pageNumArr.length - 6));
        }
      } else {
        for (let i = 1; i <= totalPages; i++) arr.push(i);
      }
      return arr;
    },
  },
  components: { OrderTableItem, BarChart, LineChart },
};
</script>


<style scoped>
.inq-table th {
  position: sticky;
  z-index: 48;
}

.rotate-sort-icon {
  transform: rotate(180deg);
}

ul li.liborder:hover,
ul li.liborder:active {
  border-bottom: solid 4px;
  border-color: #000;
}
.chart {
  height: 320px;
  width: 100%;
}
</style>